<template>
  <div class="text-center">
    <v-dialog v-model="showDialog" width="600" persistent scrollable>
      <v-card>
        <v-card-title class="headline grey lighten-2" primary-title>INBOX</v-card-title>
        <!-- เอาฟังก์ชั่นเปลี่ยนหน้ามาด้วย -->
        <v-container id=thiscontainer_approve>
        <v-data-table
          :headers="headers"
          :items="dataapprove"
          item-key="file_id"
          class="elevation-1"
          hide-default-footer
        >
          <template  v-slot:[`header.filename`]="{ header }">
            <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
          </template>
          
          <template  v-slot:[`header.status`]="{ header }">
            <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
          </template>
          <template v-slot:[`header.action`]="{ header }">
            <span class="pointer" :style="headerTable">{{ $t(header.text) }}</span>
          </template>

          <template v-slot:item="props">
            <tr>
              
              <td width="10%">{{props.item.filename}}</td>
              <td width="10%">
                <span>
                  <v-chip :color="props.item.status === 'pending' ? '#ffcc00' : 'success'" dark>
                       {{props.item.status}}
                  </v-chip>
                </span>
              </td>
              <td width="10%">
                <v-btn @click="funcApproveFile(props.item)" fab small color="success">
                  <v-icon>done</v-icon>
                </v-btn>
                <v-btn fab small color="error">
                  <v-icon>clear</v-icon>
                </v-btn>
              </td>
            </tr>
          </template>
        </v-data-table>
      </v-container>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="$emit('closedialoginbox')">Close</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import gbfGenerate from "@/globalFunctions/generateAuthorize";
export default {
  props: ["show", "approvefile"],
  computed: {
    showDialog: {
      get() {
        if(this.show === true){
             this.loaddata()
          }
          return this.show;
      }
    },
    ...mapState([
      "username",
      "authorize",
      "account_active",
      "service",
      "color"
    ]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax"
    ]),
    headerTable() {
      return (
        "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;"
      );
    },
    seticon(parameter) {
      // let parameter_ = parameter !== "" || parameter !== undefined || parameter !== null ? parameter.toLowerCase() : parameter
     

      let dataicon;
      if (parameter === "xlsx" || parameter === "xls") {
        dataicon = ["mdi-file-excel-outline", "green"];
      } else if (parameter === "pptx" || parameter === "ppt") {
        dataicon = ["mdi-file-powerpoint-outline", "orange"];
      } else if (parameter === "pdf") {
        dataicon = ["mdi-file-pdf-outline", "red"];
      } else if (parameter === "xml") {
        dataicon = ["mdi-file-code-outline", "orange"];
      } else if (parameter === "txt" || parameter === "txt") {
        dataicon = ["mdi-note-text-outline", "blue-grey"];
      } else if (
        parameter === "jpeg" ||
        parameter === "jpg" ||
        parameter === "png"
      ) {
        dataicon = ["mdi-file-image-outline", "blue-grey"];
      } else if(parameter === "avi" ||
    parameter === "mpeg" ||
    parameter === "mov" ||
    parameter === "mp4"||
    parameter === "mkv" ||
    parameter === "wmv" ||
    parameter === "3gp" ||
    parameter === "flv"){
      dataicon = ["mdi-file-video-outline", "purple"];
    }else if(parameter === "shortcut"){
      dataicon=["mdi-file-link-outline","pink"]
    }
      else {
        dataicon = ["mdi-file-question-outline", "black"];
      }

      return dataicon;
    },
    formatDate(date) {
      if (!date) return "";

      const [year, month, day] = date.split("-");
      return `${year}${month}${day}`;
    },
    formatdatetime(_datetime) {
      let dateyear =
        _datetime.split("")[0] +
        _datetime.split("")[1] +
        _datetime.split("")[2] +
        _datetime.split("")[3];
      let datemonth = _datetime.split("")[4] + _datetime.split("")[5];
      let dateday = _datetime.split("")[6] + _datetime.split("")[7];
      let hour = _datetime.split("")[8] + _datetime.split("")[9];
      let minute = _datetime.split("")[10] + _datetime.split("")[11];
      let second = _datetime.split("")[12] + _datetime.split("")[13];

      return (
        dateday +
        "/" +
        datemonth +
        "/" +
        dateyear +
        " " +
        hour +
        ":" +
        minute +
        ":" +
        second
      );
    },
    setformatdate(_date) {
      let data =
        _date.substring(8, 6) +
        "/" +
        _date.substring(6, 4) +
        "/" +
        _date.substring(0, 4);
      return data || "-";
    }
  },
  methods: {
   async loaddata(){
        let payload
        payload = {
          sender_id: this.dataAccountActive.business_info.business_id,
          sender_type:"2"
        }
        console.log("payload",payload);
        let auth = await gbfGenerate.generateToken();
       await this.axios
        .post(process.env.VUE_APP_SERVICE_GET_INBOX + "/api/pending_active_inbox",payload,{headers: { Authorization: auth.code },})
        .then(response =>{
          console.log("response",response.data.result);
          if (response.data.status === "OK") {
            this.dataapprove = response.data.result
            console.log("dataapprove",this.dataapprove);
          }
          else{
            console.log(response);
          }
        })
      },
    funcApproveFile(file){
      console.log("file",file);
    }
  },
  data() {
    return {
      dataapprove:[],
      headers: [
        {
          text: "tablefile.filename",
          value: "filename",
          sortable: false,
          width: "10%"
        },
        {
          // text: "tablefile.status",
          text: "Status",
          value: "status",
          sortable: false,
          width: "5%"
        },
        {
          text: "Action",
          value: "action",
          sortable: false,
          width: "5%"
        }
      ],
      fakeData: []
    };
  },
};
</script>

<style>

#thiscontainer_approve {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
 
}
</style>